/* General styling for the page information container */
#pageInformation {
  position: fixed;
  top: 0;
  left: 0;
  width: 630px;
  padding: 2rem;
  background-color: transparent;
  border-radius: 0 0 10px 0;
  //box-shadow: 2px 2px 2px rgba(220, 120, 200);
  overflow-y: auto;
  z-index: 100;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: white;
}

/* Style for the page information content */
#pageInformation h2 {
  font-size: 3rem;
  margin-bottom: 0.15rem;
  margin-top: 0;
}

#pageInformation h3 {
  font-size: 1.2rem;
  margin-top: 0;
  color: #e3d796;
}

#pageInformation p {
  font-size: 1rem;
  line-height: 1.4;
}

small {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

a {
  color: #40afa9;
  text-decoration: underline;
}
