/* General styling for the controls container */
#flowerGeneratorControls {
  position: fixed;
  top: 0;
  right: 0;
  width: 360px;
  height: 60vh;
  padding: 0.75rem;
  background-color: rgb(239, 239, 239);
  border-radius: 0 0 0 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  z-index: 100;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #2a2a2a;
}

/* Form styling */
form {
  display: flex;
  flex-direction: column;
}

/* Fieldset and legend styling to group related controls */
fieldset {
  border: 1px solid #818181;
  padding: 0.75em;
  margin-bottom: 0.5rem;
}

legend {
  font-size: 1.44rem;
  font-weight: bold;
  color: #333;
  padding: 0 0.25rem;
  text-decoration: underline;
}

/* Label styling for clarity and consistency */
label {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  display: block;
}

/* Input control styling for aesthetics and usability */
input[type="range"],
input[type="number"],
input[type="file"] {
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  padding: 0.5rem;
}

input[type="color"] {
  margin-bottom: 1rem;
  width: 50%;
}

input[type="submit"], button {
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
  background-color: #4f046c;
  color: white;
  border: none;
  cursor: pointer;
  padding: 0.7rem 1rem;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  margin-bottom: 0.5em;
  margin-top: 1em;
}

input[type="submit"]:hover {
  filter: hue-rotate(205deg);
}

.generate-image-button {
  background-color: #a4fd96;
  color: black;
}

.generate-image-button:hover {
  filter: hue-rotate(205deg);
}

/* Range input styling */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 7px;
  background: #ddd;
  cursor: pointer;
}

input:last-of-type {
  margin-bottom: 0.5em;
}


