// Includes IE8 support - http://jakearchibald.github.io/sass-ie/
$fix-mqs: false !default;

@mixin media-query($point) {
  @if $fix-mqs {
    @each $pt in $fix-mqs {
      @if $pt == $point {
        @content;
      }
    }
  }

  @else {
    @if $point == xl-up {
      @media (min-width: $screen-xl-min) {
        @content;
      }
    }

    @else if $point == lg-up {
      @media (min-width: $screen-lg-min) {
        @content;
      }
    }

    @else if $point == lg-only {
      @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
        @content;
      }
    }

    @else if $point == md-up {
      @media (min-width: $screen-md-min) {
        @content;
      }
    }

    @else if $point == md-only {
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        @content;
      }
    }

    @else if $point == sm-up {
      @media (min-width: $screen-sm-min) {
        @content;
      }
    }

    @else if $point == md-down {
      @media (max-width: $screen-md-min) {
        @content;
      }
    }

    @else if $point == sm-only {
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        @content;
      }
    }

    @else if $point == xs-up {
      @media (min-width: $screen-xs-min) {
        @content;
      }
    }

    @else if $point == xs-only {
      @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        @content;
      }
    }

    @else {
      @media ($point) {
        @content;
      }
    }
  }
}

$old-ie: false !default;

@mixin old-ie {
  // Only use this content if we're dealing with old IE
  @if $old-ie {
    @content;
  }
}

@mixin keyframe($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($delay, $duration, $animation) {
  animation-delay: $delay;
  animation-delay: $delay;
  animation-delay: $delay;
  animation-delay: $delay;
  animation-duration: $duration;
  animation-duration: $duration;
  animation-duration: $duration;
  animation-duration: $duration;
  animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  animation-fill-mode: forwards; /* this prevents the animation from restarting! */
  animation-name: $animation;
  animation-name: $animation;
  animation-name: $animation;
  animation-name: $animation;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin anchor-inline {
  color: $inline-link;
  text-decoration: underline;
}

// Ensure CSS grid works with IE 11 spec.
// https://css-tricks.com/browser-compatibility-css-grid-layouts-simple-sass-mixins/
// sass-lint:disable no-vendor-prefixes, no-duplicate-properties
@mixin display-grid {
  display: grid;
}

// $columns values should be delimited by a space
@mixin grid-template-columns($columns...) {
  grid-template-columns: $columns;
}

// $rows values should be delimited by a space
@mixin grid-template-rows($rows...) {
  grid-template-rows: $rows;
}

// Can be used in combination with above grid-template-X mixins.
// These result in the same output:
// @include grid-template-columns(10px grid-repeat(4, 20px) 30px);
// @include grid-template-columns(10px 20px 20px 20px 20px 30px);
@function grid-repeat($repeat, $stuff: 1fr) {
  $list: ();

  @for $i from 1 through $repeat {
    $list: append($list, $stuff, space);
  }

  @return $list;
}

@mixin grid-area($row-start, $col-start, $row-end, $col-end) {
  @include grid-row($row-start, $row-end);
  @include grid-column($col-start, $col-end);
}

@mixin grid-column($col-start, $col-end) {
  grid-column: $col-start;
  grid-column: #{$col-start} / #{$col-end};
}

@mixin grid-row($row-start, $row-end) {
  grid-row: $row-start;
  grid-row: #{$row-start} / #{$row-end};
}

@mixin grid-align-self($value) {
  align-self: $value;
}

@mixin grid-justify-self($value) {
  justify-self: $value;
}
